.button-64 {
  align-items: center;
  background-image: linear-gradient(144deg,#AF40FF, #5B42F3 50%,#00DDEB);
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -11px;
  box-sizing: border-box;
  color: #FFFFFF;
  display: inline-flex;
  font-family: 'Lato';
  font-size: 20px;
  justify-content: center;
  line-height: 1em;
  max-width: 100%;
  min-width: 140px;
  padding: 4px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  margin: 0;
}

.button-64:active,
.button-64:hover {
  outline: 0;
}

.button-64 span {
  background-color: rgb(5, 6, 45);
  padding: 16px 24px;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  transition: 300ms;
}

.button-64:hover span {
  background: none;
}

@media (min-width: 768px) {
  .button-64 {
    font-size: 24px;
    min-width: 150px;
  }
}

.button-65 {
  align-items: center;
  background-image: linear-gradient(144deg,#AF40FF, #5B42F3 50%,#00DDEB);
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -11px;
  box-sizing: border-box;
  color: #FFFFFF;
  display: inline-flex;
  font-family: 'Lato';
  font-size: 24px;
  justify-content: center;
  line-height: 1em;
  width: 270px;
  padding: 4px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  margin: 0;
}

.button-65:active,
.button-65:hover {
  outline: 0;
}

.button-65 span {
  background-color: rgb(5, 6, 45);
  padding: 16px 24px;
  border-radius: 6px;
  width: 100%;
  height: 100%;
  transition: 300ms;
}

.button-65:hover span {
  background: none;
}

@media (min-width: 768px) {
  .button-65 {
    font-size: 24px;
    min-width: 150px;
  }
}

.button-63 {
  align-items: center;
  background-image: linear-gradient(144deg,#AF40FF, #5B42F3 50%,#00DDEB);
  border: 0;
  border-radius: 8px;
  box-shadow: rgba(151, 65, 252, 0.2) 0 15px 30px -11px;
  box-sizing: border-box;
  color: #FFFFFF;
  display: inline-flex;
  font-family: 'Lato';
  font-size: 20px;
  justify-content: center;
  line-height: 1em;
  max-width: 1000%;
  min-width: 1%;
  width: 100%;
  padding: 12px;
  text-decoration: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  cursor: pointer;
  margin: 1;

}

.button-63:active,
.button-63:hover {
  outline: 0;
}

@media (min-width: 768px) {
  .button-63 {
    font-size: 20px;
    min-width: 196px;
  }
}

.page-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1; 

  }

  .title {
    align-items: center;
    display: flex;
    justify-content: center;
    font-size: 48px;
    border: none;
    margin-top: 15%;
  }

.game {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 35%;
}

.mission {
  align-items: center;
  display: flex;
  justify-content: center;
}

.playbox {
  color: var(--text);
  background-color: var(--bg);
  margin: 1;
  padding: 30px;
  border-radius: 20px;
  min-width: 350px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: inline;
}

body {
  color: var(--text);
  background-color: var(--bg);
  font-size: 1.15rem;
  line-height: 1.5;
  display: grid;
  grid-template-columns: 1fr min(45rem, 90%) 1fr;
  margin: 0;
  font-family: 'Lato';
  padding: 0;
}

body > * {
  grid-column: 2;
}